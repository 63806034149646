import * as Yup from "yup";
import { propertySchema } from "../Shared/PropertySchema";

const header = "Property details";

const description =
  "Please provide details of any properties you own. This information can significantly impact the personalised financial advice we provide.";

const schema = propertySchema;

export { header, description, schema };
