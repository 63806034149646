import * as Yup from "yup";
import { pensionSchema } from "../Shared/PensionSchema";
import { groupedOptions as groupedPensionProviders } from "../../../../models/PensionProviders";
import { pensionTypeLookup } from "../../Lookups";

const header = "Pension details";

const description = "Tell us about your pension";

const schema = pensionSchema;

export { header, description, schema };
