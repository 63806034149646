// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKdnM-CswcvnUzowBcXxNl4c1rcPzHTfk",
  authDomain: "fwsite-808e2.firebaseapp.com",
  projectId: "fwsite-808e2",
  storageBucket: "fwsite-808e2.firebasestorage.app",
  messagingSenderId: "202244532376",
  appId: "1:202244532376:web:afb5f3f15cf505030248ed"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const facebookAuthProvider = new FacebookAuthProvider();
