import { MedicalInfoSchema } from "../Shared/MedicalInfoSchema";

const header = "Medical details";

const description =
  "Tell us about your lifestyle, medical conditions and any medications";

const schema = MedicalInfoSchema;

export { header, description, schema };
