import * as Yup from "yup";
export const AnnuityQuoteShape = Yup.object().shape({
  annuity: Yup.object().shape({
    escalationRate: Yup.string()
      .required()
      .label("What is your preferred interest rate?")
      .meta({
        control: {
          componentType: "Lookup",
          props: {
            label: "What is your preferred interest rate?",
            description: "",
            name: "annuity.escalationRate",
            tooltip: "",
            values: [
              { label: "2%", value: "100993" },
              { label: "2.5%", value: "5889" },
              { label: "3%", value: "5890" },
              { label: "5%", value: "5891" },
              { label: "Level", value: "5888" },
              { label: "LPI", value: "5893" },
              { label: "None", value: "100271" },
              { label: "RPI", value: "5892" },
            ],
            required: true,
          },
        },
      }),
    paymentFrequency: Yup.string()
      .required()
      .label("What frequency would you like your Annuity payments?")
      .meta({
        control: {
          componentType: "RadioBoxes",
          props: {
            label: "What frequency would you like your Annuity payments?",
            description: "",
            name: "annuity.paymentFrequency",
            tooltip: "",
            items: [
              { label: "Half-Yearly", value: "5886" },
              { label: "Monthly", value: "5884" },
              { label: "Quarterly", value: "5885" },
              { label: "Yearly", value: "5887" },
            ],
            required: true,
          },
        },
      }),
    paymentTiming: Yup.string()
      .required()
      .label("When would you like your payments made?")
      .meta({
        control: {
          componentType: "RadioBoxes",
          props: {
            label: "When would you like your payments made?",
            description: "",
            name: "annuity.paymentTiming",
            tooltip: "",
            items: [
              { label: "In Advance", value: "5880" },
              { label: "In Arrears", value: "5879" },
              { label: "In Arrears (with proportion)", value: "6055" },
            ],
            required: true,
          },
        },
      }),
    guaranteePeriod: Yup.string()
      .required()
      .label("How many years guarantee would you like on your annuity?")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "How many years guarantee would you like on your annuity?",
            description: "",
            name: "annuity.guaranteePeriod",
            tooltip: "",
            placeholder: "0",
            inputType: "number",
            required: true,
            validationType: "number",
            minValue: 0,
            maxValue: 20,
          },
        },
      }),
    reversionaryTypeYesNo: Yup.string()
      .required()
      .label(
        "Would you like your pension to pass to someone else when you die?"
      )
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label:
              "Would you like your pension to pass to someone else when you die?",
            description: "",
            name: "annuity.reversionaryTypeYesNo",
            tooltip: "",
            required: true,
          },
        },
      }),
    reversionaryBenefit: Yup.string()
      .when("annuity.reversionaryTypeYesNo", {
        is: (val: string) => val === "true",
        then: Yup.string().required(),
        otherwise: Yup.string().optional(),
      })
      .label("What percentage of your pension would you like to pass on?")
      .meta({
        control: {
          componentType: "RadioBoxes",
          props: {
            label: "What percentage of your pension would you like to pass on?",
            description: "",
            name: "annuity.reversionaryBenefit",
            tooltip: "",
            items: [
              { label: "None", value: "100272" },
              { label: "50%", value: "5914" },
              { label: "66%", value: "5915" },
              { label: "100%", value: "5916" },
            ],
          },
          dependsOnFields: [
            {
              fieldName: "annuity.reversionaryTypeYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),
    reversionaryStart: Yup.string()
      .when("annuity.reversionaryTypeYesNo", {
        is: (val: string) => val === "true",
        then: Yup.string().required(),
        otherwise: Yup.string().optional(),
      })
      .label("When would you like your spouse cover to start?")
      .meta({
        control: {
          componentType: "RadioBoxes",
          props: {
            label: "When would you like this cover to start?",
            description: "",
            name: "annuity.reversionaryStart",
            tooltip: "",
            items: [
              { label: "Next Due Date", value: "5912" },
              { label: "Date of Death", value: "5913" },
            ],
          },
          dependsOnFields: [
            {
              fieldName: "annuity.reversionaryTypeYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),
    reversionaryType: Yup.string()
      .when("annuity.reversionaryTypeYesNo", {
        is: (val: string) => val === "true",
        then: Yup.string().required(),
        otherwise: Yup.string().optional(),
      })
      .label("Who would you like to pass this cover on to?")
      .meta({
        control: {
          componentType: "RadioBoxes",
          props: {
            label: "Who would you like to pass this cover on to?",
            description: "",
            name: "annuity.reversionaryType",
            tooltip: "",
            items: [
              { label: "Spouse/Partner", value: "5917" },
              { label: "Dependant", value: "5918" },
            ],
          },
          dependsOnFields: [
            {
              fieldName: "annuity.reversionaryTypeYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),
  }),
});
