import * as Yup from "yup";
const header = "About You";

const description =
  "Tell us a little about yourself (no contact details needed!).";

const stepperDescription =
  "Tell us a little about yourself (no contact details needed!).";

const schema = Yup.object().shape({
  title: Yup.string()
    .required()
    .label("Title")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Title",
          description: "",
          name: "title",
          tooltip: "",
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
      },
    }),

  firstName: Yup.string()
    .required()
    .label("First Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "First Name",
          description: "",
          name: "firstName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  lastName: Yup.string()
    .required()
    .label("Last Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Last Name",
          description: "",
          name: "lastName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  dateOfBirth: Yup.string()
    .required()
    .nullable()
    .label("Date of Birth")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "dateOfBirth",
          label: "Your Date of Birth",
          required: true,
          description: "You must be over 55 to get quotes.",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1943,
          endYear: new Date().getFullYear() - 50,
        },
      },
    }),
  postcode: Yup.string()
    .required()
    .label("Postcode")
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Your Postcode",
          description: "",
          name: "postcode",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addSeparatorBelow: true,
          validationType: "postcode",
        },
      },
    }),
});
export { header, description, schema, stepperDescription };
