import * as Yup from "yup";
import { Notice } from "../../../common/cards/Notice";

export const AnnuityQuotePage1Schema = Yup.object().shape({
  annuity: Yup.object().shape({
    pensionFund: Yup.number()
      .required()
      .label("Pension Fund")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label:
              "What is the approximate current value of your pension fund(s)?",
            description:
              "If you have multiple pensions, please combine them and enter that amount",
            name: "annuity.pensionFund",
            required: true,
            placeholder: "",
            inputType: "number",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 10000000,
          },
        },
      }),

    plannedRetirementDate: Yup.string()
      .required()
      .label("Planned Retirement Date")
      .meta({
        control: {
          componentType: "DateMMYYYY",
          props: {
            name: "annuity.plannedRetirementDate",
            label: "When are you planning on retiring?",
            required: true,
            description: "",
            tooltip: {
              button: (
                <a
                  className="ms-10 btn btn-small btn-outline form-text"
                  data-bs-toggle="collapse"
                  data-bs-target="#plannedRetirementDate"
                  aria-expanded="false"
                  aria-controls="plannedRetirementDate"
                >
                  Why?
                </a>
              ),
              notice: (
                <div className="collapse pb-5 pt-3" id="plannedRetirementDate">
                  <Notice
                    noticeClasses={"bg-light-warning"}
                    icon={undefined}
                    iconClasses={null}
                    header={undefined}
                    content={
                      "Letting us know when you plan on retiring can help us tailor your journey to what you need"
                    }
                    button={null}
                  />
                </div>
              ),
            },
            placeholder: "",
            prefix: undefined,
            startYear: new Date().getFullYear(),
            endYear: new Date().getFullYear() + 10,
            addSeparatorBelow: true,
          },
        },
      }),
  }),
});
