import * as Yup from "yup";
import { pensionSchema } from "../Shared/PensionSchema";
import { propertySchema } from "../Shared/PropertySchema";
import { PersonalDetailsSchema } from "./PersonalDetailsSchema";
import { MedicalInfoSchema } from "./MedicalInfoSchema";
import { employmentSchema } from "./EmploymentSchema";

const AllData = Yup.object().shape({
  //add all shared yup schemas
  PersonalDetailsSchema,
  MedicalInfoSchema,
  pensionSchema,
  propertySchema,
  employmentSchema,
});

export { AllData };
