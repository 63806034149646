import * as Yup from "yup";
import { groupedOptions as groupedOccupations } from "../../../../models/EmploymentData";
import { employmentSchema } from "../Shared/EmploymentSchema";
import { employmentStatusLookup, employmentTypeLookup } from "../../Lookups";

const header = "Employment details";

const description = "Tell us about your employment history";

const schema = employmentSchema;

export { header, description, schema };
