import * as Yup from "yup";

export const propertySchema = Yup.object().shape({
  propertyDetails: Yup.array()
    .of(
      Yup.object().shape({
        address: Yup.string().label("Address"),
        city: Yup.string().label("City"),
        postcode: Yup.string()
          .matches(
            /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i,
            "Invalid UK Postcode"
          )
          .label("Postcode"),
        estimatedValue: Yup.string().label("Estimated Value"),
        ownership: Yup.string().label("Ownership"),
        residenceType: Yup.string().label("Residence Type"),
      })
    )
    .label("Properties")
    .meta({
      fieldArrayName: "properties",
      fieldArrayDescription:
        "Please add details for any properties you have below",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "InputBox",
          props: {
            name: "address",
            label: "What is the address?",
            required: true,
            description: "Please enter your house name/number and street",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "city",
            label: "What is the city?",
            required: true,
            description: "Please enter the city",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "Postcode",
          props: {
            label: "What is the postcode?",
            description: "",
            name: "postcode",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "text",
            prefix: undefined,
            addressLine1FieldName: "",
            cityFieldName: "",
            includeFindAddress: false,
            validationType: "postcode",
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "estimatedValue",
            label: "What is the estimated value of your property?",
            required: true,
            description: "Please enter the estimated value of your property",
            placeholder: "",
            inputType: "number",
            tooltip: "",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 10000000,
          },
        },
      ],
    }),
});
