import * as Yup from "yup";
import { employmentStatusLookup, employmentTypeLookup } from "../../Lookups";
import { groupedOptions as groupedOccupations } from "../../../../models/EmploymentData";

export const employmentSchema = Yup.object().shape({
  employments: Yup.array()
    .of(
      Yup.object().shape({
        employmentStatus: Yup.string().label("Employment Status"),
        employmentType: Yup.string().label("Employment Type"),
        occupation: Yup.string().label("Occupation"),
        startDate: Yup.string().label("Start Date"),
        endDate: Yup.string().label("End Date"),
      })
    )
    .label("Employment Details")
    .meta({
      fieldArrayName: "employments",
      fieldArrayDescription: "Please add employment details below",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "Lookup",
          props: {
            label: "Employment Status",
            description: "",
            name: "employmentStatus",
            tooltip: "",
            values: employmentStatusLookup,
          },
        },
        {
          componentType: "LookupWithSearchAndGroups",
          props: {
            name: "occupation",
            label: "Select your occupation",
            required: false,
            description: "Select your occupation",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "",
            values: groupedOccupations,
          },
        },
        {
          componentType: "Lookup",
          props: {
            name: "employmentType",
            label: "What type of employment was this?",
            required: false,
            description: "",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "",
            values: employmentTypeLookup,
          },
        },
        {
          componentType: "DateMMYYYY",
          props: {
            name: "dateStarted",
            label: "Date Started",
            required: true,
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: 1920,
            endYear: new Date().getFullYear(),
          },
        },
        {
          componentType: "DateMMYYYY",
          props: {
            name: "dateEnded",
            label: "Date ended",
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: 1920,
            endYear: new Date().getFullYear(),
          },
          dependsOnFields: [
            {
              fieldName: "employmentType",
              fieldValue: "3665",
            },
          ],
        },
      ],
    }),
});
