import { useEffect, useState } from "react";
import { usePortal } from "../../context/PortalContext";
import { IJournalDocument, IPension } from "../../models/ClientData";
import { Notice } from "../../modules/common/cards/Notice";
import { DocumentsCollection } from "../../modules/common/other/DocumentsCollection";
import { KTSVG } from "../../_metronic/helpers";

export const MyDocuments = () => {
  const { clientData }: any = usePortal();
  const [portalDocsSignatures, setPortalDocumentsSignatures] = useState(Array<IJournalDocument>());
  const [portalDocs, setPortalDocuments] = useState(Array<IJournalDocument>());

  useEffect(() => {
    // Docs that require a signature
    let docsRequiringSignature = Array<IJournalDocument>();

    clientData.journal?.forEach((clientDoc: IJournalDocument) => { 
      if (clientDoc.requiresSignature) {
        docsRequiringSignature.push(clientDoc) 
      }
    });

    clientData.annuity?.journalDocuments?.forEach((annuityDoc: IJournalDocument) => { 
      if (annuityDoc.requiresSignature) {
        docsRequiringSignature.push(annuityDoc) 
      }
    });

    clientData.pensions?.forEach((pension: IPension) => { 
      if (pension && Array.isArray(pension.journalDocuments) && pension.journalDocuments.length > 0) {
        pension?.journalDocuments?.forEach((pensionDoc: IJournalDocument) => {      
          if (pensionDoc.requiresSignature) {
            docsRequiringSignature.push(pensionDoc)
          }
        }) 
      }
    });

    setPortalDocumentsSignatures(docsRequiringSignature)

    // Docs that don't require a signature
    let docsNoSignature = Array<IJournalDocument>();

    clientData.journalDocuments.forEach((clientDoc: IJournalDocument) => { 
      if (!clientDoc.requiresSignature) {
        docsNoSignature.push(clientDoc) 
      }
    });

    clientData.annuity?.journalDocuments.forEach((annuityDoc: IJournalDocument) => { 
      if (!annuityDoc.requiresSignature) {
        docsNoSignature.push(annuityDoc) 
      }
    });

    clientData.pensions?.forEach((pension: IPension) => { 
      if (pension && Array.isArray(pension.journalDocuments) && pension.journalDocuments.length > 0) {
        pension?.journalDocuments?.forEach((pensionDoc: IJournalDocument) => { 
          if (!pensionDoc.requiresSignature) {
            docsNoSignature.push(pensionDoc) 
          }
        }) 
      }
    });

    setPortalDocuments(docsNoSignature);
  }, []);

  return (
    <>
      <div className="flex-wrap flex-stack mb-6">
        {portalDocsSignatures && portalDocsSignatures.length > 0 ? (
          <>
            <h3 className="fw-bolder my-2">Documents That Require Your Signature</h3>
            <Notice
              noticeClasses={
                "mb-10 bg-light-warning border border-warning"
              }
              icon={
                <KTSVG
                  path="media/icons/duotune/art/art008.svg"
                  className="svg-icon svg-icon-3x svg-icon-dark"
                />
              }
              iconClasses={""}
              header={"Signature Required"}
              content={
                <>
                  <div className="mt-5">
                    Please review and sign these documents so that we can progress with your case.
                  </div>
                </>
              }
              button={null}
            />
            <DocumentsCollection
              documents={[
                ...portalDocsSignatures.map((item: IJournalDocument, index) => ({
                  name: item.name,
                  createdDate: item.date,
                  data: item.file,
                  type: item.type,
                  docusignRedirectURL: item.redirectURL,
                  requiresSignature: item.requiresSignature
                })),
              ]}
            />
          </>
        ) : null }
      </div>
      <h3 className="fw-bolder my-2">All Documents</h3>
      <div className="flex-wrap flex-stack mb-6">    
        {portalDocs && portalDocs.length > 0 ? (
          <>
            <DocumentsCollection
              documents={[
                ...portalDocs.map((item: IJournalDocument, index) => ({
                  name: item.name,
                  createdDate: item.date,
                  data: item.file,
                  type: item.type,
                  docusignRedirectURL: item.redirectURL,
                  requiresSignature: item.requiresSignature
                })),
              ]}
            />
          </>
        ) : (
          <div className="card h-100">
            <div className="card-body d-flex justify-content-center text-center flex-column p-8 fs-5 fw-bolder">
              <p>You don't have any documents here yet...</p>
            </div>
          </div>         
        )}
      </div>
    </>
  );
};
