import * as Yup from "yup";
import { AnnuityQuoteShape } from "../Shared/AnnuityQuoteShape";

const header = "Additional Details";

const description =
  "Provide us with a few additional details to help provide the best quote for your circumstances.";

const stepperDescription = "Provide a few additional details.";

const schema = AnnuityQuoteShape;

export { header, description, schema, stepperDescription };
