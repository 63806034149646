import * as Yup from "yup";
import { groupedOptions as groupedMedicalConditions } from "../../../../models/MedicalConditionsData";
import { groupedOptions as groupedMedications } from "../../../../models/MedicationData";
import { medicalConditionSchema } from "../Shared/MedicalConditionSchema";
import { medicationSchema } from "../Shared/MedicationSchema";
import { useState } from "react";
import { MedicalInfoSchema } from "../Shared/MedicalInfoSchema";
const header = "Further Health & Lifestyle";

const description = "A few more details about your health and lifestyle";
const stepperDescription = "A few more details about your health and lifestyle";

const schema = MedicalInfoSchema;

export { header, description, schema };
