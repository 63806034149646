import React, { useState, useEffect, ReactElement } from "react";

interface IProps {
  fileType: string;
  fileName: string;
  docusignRedirectURL: string;
  children: ReactElement;
}

const DocumentDigitalSignature = (props: IProps) => {
  const [fileName, setFileName] = useState<any>(null);

  useEffect(() => {
    setFileName(props.fileName);
  }, []);

  return (
    <a
      className="card-body d-flex justify-content-center text-center flex-column p-8"
      href={props.docusignRedirectURL}
      target="_blank"
    >
      <div>{props.children}</div>
    </a>
  );
};

export default DocumentDigitalSignature;
