import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import {
  IClientData,
  IJournalDocument,
  IJournalNote,
  IPension,
} from "../models/ClientData";
import { IQuoteData } from "../models/QuoteData";
import { IGetQuotesRequestData } from "../models/GetQuotesRequestData";
import axios from "axios";
import appInsights from "../azureAppInsights";

const PortalContext = createContext({});

export function PortalContextProvider({ children }: { children: ReactNode }) {
  const [user, loading, error] = useAuthState(auth);
  const [firstLogin, setFirstLogin] = useState(false);

  const [clientData, setClientDataContext] = useState<
    IClientData | undefined
  >();

  // Function to handle the setClientData
  const setClientData = (data: IClientData | undefined) => {
    if (!data) {
      setClientDataContext(undefined);
    } else {
      // Default previousNames yes/no
      let previousNamesYesNo = "false";
      let person2PreviousNamesYesNo = "false";

      if (data.previousNames && data.previousNames.length > 0) {
        previousNamesYesNo = "true";
      }

      if (data.person2previousNames && data.person2previousNames.length > 0) {
        person2PreviousNamesYesNo = "true";
      }

      // Default spousePartner yes/no
      let spousePartnerYesNo = "false";

      if (data.person2FirstName && data.person2FirstName.length > 0) {
        spousePartnerYesNo = "true";
      }

      // Default previousSmoker yes/no and previousOrCurrentSmoker yes/no
      let isPreviousSmoker = "false";
      let isCurrentOrPreviousSmoker = "false";

      if (
        data.medicalInformation?.yearMonthStartedSmoking &&
        data.medicalInformation?.yearMonthStartedSmoking.length > 0 &&
        (!data.medicalInformation?.yearMonthStoppedSmoking ||
          data.medicalInformation?.yearMonthStoppedSmoking.length === 0)
      ) {
        isCurrentOrPreviousSmoker = "true";
      }

      if (
        data.medicalInformation?.yearMonthStartedSmoking &&
        data.medicalInformation?.yearMonthStartedSmoking.length > 0 &&
        data.medicalInformation?.yearMonthStoppedSmoking &&
        data.medicalInformation?.yearMonthStoppedSmoking.length > 0
      ) {
        isPreviousSmoker = "true";
      }

      // Default medical conditions yes/no and medications yes/no
      let hasMedicalConditionsYesNo = "false";
      let hasMedicationsYesNo = "false";

      if (
        data.medicalInformation &&
        Array.isArray(data.medicalInformation.medicalConditionList) &&
        data.medicalInformation.medicalConditionList.length > 0
      ) {
        hasMedicalConditionsYesNo = "true";
      }

      if (
        data.medicalInformation &&
        Array.isArray(data.medicalInformation.medicationList) &&
        data.medicalInformation.medicationList.length > 0
      ) {
        hasMedicationsYesNo = "true";

        data.medicalInformation.medicationList.map((medication) => {
          if (medication.endDate) {
            medication.stillTakeMedication = "false";
          } else {
            medication.stillTakeMedication = "true";
          }

          if (medication.dosage && parseInt(medication.dosage) > 0) {
            console.log("KNOWN");
            medication.dosageKnown = "true";
          } else {
            medication.dosageKnown = "false";
          }
        });
      }

      // Map Client journal notes and docs
      let clientJournalDocs: Array<IJournalDocument> = [];

      if (
        data &&
        Array.isArray(data.journalNotes) &&
        data.journalNotes.length > 0
      ) {
        data.journalNotes.map((journalNote: IJournalNote, index) => {
          for (let i = 0; i < 11; i++) {
            const journalFileName = `attachment${i}Name`;
            const journalFile = `attachment${i}`;

            if (
              journalNote[journalFileName] &&
              journalNote[journalFileName] !== ""
            ) {
              clientJournalDocs.push({
                name: journalNote[journalFileName],
                file: journalNote[journalFile],
                date: journalNote.date,
                type: journalNote[journalFileName].split(".")[1],
                redirectURL:
                  journalNote[journalFileName].requiresSignature === "true" &&
                  journalNote[journalFileName].docusignEnvelopeID !== null
                    ? journalNote[journalFileName].docusignEnvelopeID
                    : null,
                requiresSignature:
                  journalNote[journalFileName].requiresSignature,
              });
            }
          }
        });
      }

      // Map Annuity journal notes and docs
      let annuityJournalDocs: Array<IJournalDocument> = [];

      if (
        data.annuity &&
        Array.isArray(data.annuity?.journalNotes) &&
        data.annuity.journalNotes.length > 0
      ) {
        data.annuity.journalNotes.map((journalNote: IJournalNote, index) => {
          for (let i = 0; i < 11; i++) {
            const journalFileName = `attachment${i}Name`;
            const journalFile = `attachment${i}`;

            if (
              journalNote[journalFileName] &&
              journalNote[journalFileName] !== ""
            ) {
              annuityJournalDocs.push({
                name:
                  journalNote.requiresSignature === "true"
                    ? journalNote.subject
                    : journalNote[journalFileName],
                file:
                  journalNote.requiresSignature === "true"
                    ? ""
                    : journalNote[journalFile],
                date: journalNote.date,
                type:
                  journalNote.requiresSignature === "true"
                    ? "pdf"
                    : journalNote[journalFileName].split(".")[1],
                redirectURL:
                  journalNote.requiresSignature === "true" &&
                  journalNote.redirectUrl !== null
                    ? journalNote.redirectUrl
                    : null,
                requiresSignature: journalNote.requiresSignature === "true",
              });
            }
          }
        });
      }

      // Map Pensions
      let pensions: Array<IPension> = [];

      if (
        data.pensions &&
        Array.isArray(data.pensions) &&
        data.pensions.length > 0
      ) {
        pensions = data.pensions;

        // Map Pension journal notes and docs
        pensions.forEach((pension) => {
          let pensionJournalDocs: Array<IJournalDocument> = [];

          if (
            pension &&
            Array.isArray(pension.journalNotes) &&
            pension.journalNotes.length > 0
          ) {
            pension.journalNotes.map((journalNote: IJournalNote) => {
              for (let i = 0; i < 11; i++) {
                const journalFileName = `attachment${i}Name`;
                const journalFile = `attachment${i}`;

                if (
                  journalNote[journalFileName] &&
                  journalNote[journalFileName] !== ""
                ) {
                  pensionJournalDocs.push({
                    name: journalNote[journalFileName],
                    file: journalNote[journalFile],
                    date: journalNote.date,
                    type: journalNote[journalFileName].split(".")[1],
                    redirectURL:
                      journalNote.requiresSignature === "true" &&
                      journalNote.docusignEnvelopeID !== null
                        ? journalNote.docusignEnvelopeID
                        : null,
                    requiresSignature: journalNote.requiresSignature,
                  });
                }
              }
            });

            pension.journalDocuments = pensionJournalDocs;
          }
        });
      }

      // Add default values
      const clientDataWithDefaults = {
        ...data,
        hasPreviousNamesYesNo: previousNamesYesNo,
        person2HasPreviousNamesYesNo: person2PreviousNamesYesNo,
        addSpouseOrPartnerYesNo: spousePartnerYesNo,
        journalDocuments: clientJournalDocs,
        medicalInformation: {
          ...(data.medicalInformation ?? {}),
          medicalConditionsYesNo: hasMedicalConditionsYesNo,
          medicationsYesNo: hasMedicationsYesNo,
          previousSmokerYesNo: isPreviousSmoker,
          previousOrCurrentSmokerYesNo:
            isPreviousSmoker === "true" || isCurrentOrPreviousSmoker === "true"
              ? "true"
              : "false",
        },
        pensions: pensions,
        annuity: {
          ...data.annuity,
          milestones: [
            {
              name: "IFA advice & recommendation",
              completed:
                data.annuity && data.annuity.iFACallCompletedDate
                  ? true
                  : false,
              completedDate:
                data.annuity && data.annuity.iFACallCompletedDate
                  ? data.annuity.iFACallCompletedDate.replace("00:00:00", "")
                  : "",
            },
            {
              name: "Pension checks",
              completed:
                data.annuity && data.annuity.pensionChecksCompletedDate
                  ? true
                  : false,
              completedDate:
                data.annuity && data.annuity.pensionChecksCompletedDate
                  ? data.annuity.pensionChecksCompletedDate.replace(
                      "00:00:00",
                      ""
                    )
                  : "",
            },
            {
              name: "Application pack sent",
              completed:
                data.annuity && data.annuity.applicationPackSentToClientDate
                  ? true
                  : false,
              completedDate:
                data.annuity && data.annuity.applicationPackSentToClientDate
                  ? data.annuity.applicationPackSentToClientDate.replace(
                      "00:00:00",
                      ""
                    )
                  : "",
            },
            {
              name: "Application pack back",
              completed:
                data.annuity && data.annuity.applicationPackBackDate
                  ? true
                  : false,
              completedDate:
                data.annuity && data.annuity.applicationPackBackDate
                  ? data.annuity.applicationPackBackDate.replace("00:00:00", "")
                  : "",
            },
            {
              name: "Application submitted to provider",
              completed:
                data.annuity && data.annuity.applicationSubmittedToProviderDate
                  ? true
                  : false,
              completedDate:
                data.annuity && data.annuity.applicationSubmittedToProviderDate
                  ? data.annuity.applicationSubmittedToProviderDate.replace(
                      "00:00:00",
                      ""
                    )
                  : "",
            },
            {
              name: "Completion",
              completed:
                data.annuity && data.annuity.completedDate ? true : false,
              completedDate:
                data.annuity && data.annuity.completedDate
                  ? data.annuity.completedDate.replace("00:00:00", "")
                  : "",
            },
          ],
          journalDocuments: annuityJournalDocs,
          escalationRate: data.annuity?.escalationRate,
          paymentFrequency: data.annuity?.paymentFrequency,
          paymentTiming: data.annuity?.paymentTiming,
          guaranteePeriod: data.annuity?.guaranteePeriod,
          reversionaryType: data.annuity?.reversionaryType,
          reversionaryBenefit: data.annuity?.reversionaryBenefit,
          reversionaryStart: data.annuity?.reversionaryStart,
        },
      };

      setClientDataContext(clientDataWithDefaults);
    }
  };

  const [quoteData, setQuoteData] = useState<IQuoteData | undefined>(undefined);

  //const APIURL = "https://localhost:7081/api";
  // const APIURL = "https://portalwebapiapi.azure-api.net/api";
  //const APIURL = "https://penseportalwebapi.azure-api.net/api";
  const APIURL = "https://matrixframeworkportalwebapi.azure-api.net/api";

  async function apiUpdateClientData(
    updateData: IClientData | undefined
  ): Promise<IClientData> {
    let responseData: IClientData = {};

    if (updateData) {
      // update previousNames yes/no
      if (updateData.hasPreviousNamesYesNo === "false") {
        updateData.previousNames = "";
        updateData.previousNamesDateChanged = "";
      }

      if (updateData.person2HasPreviousNamesYesNo === "false") {
        updateData.person2previousNames = "";
        updateData.person2previousNamesDateChanged = "";
      }

      // default spousePartner yes/no
      if (updateData.addSpouseOrPartnerYesNo === "false") {
        updateData.person2FirstName = "";
      }

      if (updateData.medicalInformation) {
        let medicalInfo: any = updateData.medicalInformation;

        // default previousSmoker yes/no and previousOrCurrentSmoker yes/no
        if (medicalInfo.previousOrCurrentSmokerYesNo === "false") {
          medicalInfo.yearMonthStartedSmoking = "";
          medicalInfo.monthStartedSmoking = "";
          medicalInfo.yearStartedSmoking = "";
          medicalInfo.yearMonthStoppedSmoking = "";
          medicalInfo.monthStoppedSmoking = "";
          medicalInfo.yearStoppedSmoking = "";
          medicalInfo.cigarettesPerDay = 0;
          medicalInfo.gramsOfTobaccoPerDay = 0;
          medicalInfo.gramsOfPipeTobaccoPerDay = 0;
          medicalInfo.cigarsPerday = 0;
        }

        if (medicalInfo.previousSmokerYesNo === "false") {
          medicalInfo.yearMonthStoppedSmoking = "";
          medicalInfo.monthStoppedSmoking = "";
          medicalInfo.yearStoppedSmoking = "";
        }

        // default medical conditions yes/no and medications yes/no
        if (medicalInfo.medicalConditionsYesNo === "false") {
          medicalInfo.medicalConditionList = [];
        }

        if (medicalInfo.medicationsYesNo === "false") {
          medicalInfo.medicationList = [];
        }

        // default annuity quote reversionary fields
        if (updateData?.annuity?.reversionaryTypeYesNo === "false") {
          updateData.annuity.reversionaryType = "5919"; // None
          updateData.annuity.reversionaryBenefit = "100272"; // None
          updateData.annuity.reversionaryStart = "";
        }
      }
    }

    if (!updateData) {
      return responseData;
    }

    try {
      const idToken = await auth.currentUser?.getIdToken();
      let url = `${APIURL}/client/update`;

      const response = await axios.post(url, updateData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
      });

      console.log(response);
      responseData = response.data;
    } catch (error) {
      console.log(error);

      let appInsightsError: Error = {
        name: "Portal Context - apiUpdateClientData Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });
    }

    return responseData;
  }

  async function returnClientData() {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      const url = `${APIURL}/client`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `${idToken}`,
        },
      });
      setClientData(response.data);
      return 200; // Success
    } catch (error: any) {
      console.log(error);

      if (error.response && error.response.status === 404) {
        return 404; // Not Found
      }

      appInsights.trackException({ exception: error });
      return 500; // Internal Server Error
    }
  }

  async function insertClientData() {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      let clientJSONObject = clientData ?? {};
      let url = `${APIURL}/client/insert`;

      // Check if clientData has first, last name and email, set if not before posting from user auth
      clientDataChecks(clientJSONObject);

      const response = await axios.post(url, clientJSONObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
      });

      console.log(response);
      setClientData(response.data);
      setFirstLogin(true);
    } catch (error: any) {
      console.log(error);
      appInsights.trackException({ error });
    }
  }

  async function retrieveClientData() {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      console.log(idToken);
      let clientJSONObject = clientData ?? {};
      let url = `${APIURL}/client/retrieve`;

      clientDataChecks(clientJSONObject);

      const response = await axios.post(url, clientJSONObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
      });

      console.log(response);

      return new Promise<void>((resolve) => {
        setClientData(response.data);
        setFirstLogin(true);
        resolve();
      });
    } catch (error: any) {
      console.log(error);
      appInsights.trackException({ error });
    }
  }

  function clientDataChecks(clientJSONObject: IClientData) {
    if (!clientJSONObject?.firstName || clientJSONObject.firstName === null) {
      if (user?.displayName) {
        const firstName =
          user.displayName.indexOf(" ") === -1
            ? user.displayName
            : user.displayName.split(" ")[0];
        clientJSONObject.firstName = firstName;
      }
    }

    if (!clientJSONObject?.lastName || clientJSONObject.lastName === null) {
      if (user?.displayName) {
        const lastName =
          user.displayName.indexOf(" ") === -1
            ? ""
            : user.displayName.split(" ")[1];
        clientJSONObject.lastName = lastName;
      }
    }

    if (!clientJSONObject?.email || clientJSONObject.email === null) {
      if (user?.email) {
        clientJSONObject.email = user.email;
      }
    }
  }

  async function clearClientContext() {
    setClientData(undefined);
  }

  async function getNewQuotes(quoteRequestData: IClientData | undefined) {
    try {
      let url = `${APIURL}/quote/iress`;
      const response = await new Promise((resolve, reject) => {
        auth.currentUser?.getIdToken().then((idToken) => {
          axios
            .post(url, quoteRequestData, {
              headers: {
                Authorization: `${idToken}`,
              },
            })
            .then((response) => {
              const updatedClientData = {
                ...clientData,
                annuity: {
                  ...clientData?.annuity,
                  quotes: response.data,
                },
              };
              setClientData(updatedClientData);
              resolve(updatedClientData);
            })
            .catch((error: Error) => {
              reject(error);
              appInsights.trackException({ exception: error });
            });
        });
      });
      return response;
    } catch (error) {
      console.log(error);
      let appInsightsError: Error = {
        name: "Portal Context - getNewQuotes Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });
      return {};
    }
  }

  async function getNewQuickQuotes(quoteRequestData: IClientData | undefined) {
    try {
      let url = `${APIURL}/quote/iressquick`;
      const response = await new Promise((resolve, reject) => {
        axios
          .post(url, quoteRequestData, {
            headers: {},
          })
          .then((response) => {
            const updatedClientData = {
              ...clientData,
              annuity: {
                ...clientData?.annuity,
                quotes: response.data,
              },
            };
            setClientData(updatedClientData);
            resolve(updatedClientData);
          })
          .catch((error: Error) => {
            reject(error);
            appInsights.trackException({ exception: error });
          });
      });
      return response;
    } catch (error) {
      console.log(error);
      let appInsightsError: Error = {
        name: "Portal Context - getNewQuickQuotes Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });
      return {};
    }
  }

  function buildGetQuotesRequest(): IGetQuotesRequestData {
    let quoteData: any = {
      client1: {},
    };

    let title = clientData?.title?.toLowerCase();
    switch (title) {
      case "mr":
        quoteData.client1.title = 0;
        break;
      case "mrs":
        quoteData.client1.title = 1;
        break;
      case "miss":
        quoteData.client1.title = 2;
        break;
      case "ms":
        quoteData.client1.title = 3;
        break;
      default:
        quoteData.client1.title = 4;
        break;
    }

    quoteData.client1.forenames = clientData?.firstName;
    quoteData.client1.surname = clientData?.lastName;
    quoteData.client1.middlename = clientData?.middleName;

    if (clientData?.postcode) {
      quoteData.client1.address.postcode = clientData?.postcode;
    }

    const quoteDataold: IGetQuotesRequestData = {
      client1: {
        enhancedUnderwriting: {
          lifestyle: {
            height_value:
              (clientData?.medicalInformation?.heightFeet
                ? clientData.medicalInformation.heightFeet * 12
                : 0) +
              (clientData?.medicalInformation?.heightInches
                ? clientData.medicalInformation.heightInches
                : 0),
            weight_value:
              (clientData?.medicalInformation?.weightStone
                ? clientData.medicalInformation.weightStone * 14
                : 0) +
              (clientData?.medicalInformation?.weightPounds
                ? clientData.medicalInformation.weightPounds
                : 0),
            waist_value: clientData?.medicalInformation?.waistMeasurement
              ? clientData?.medicalInformation?.waistMeasurement
              : 0,
            unitsOfAlcoholKnown: true,
            units_of_alcohol: clientData?.medicalInformation
              ?.alcoholUnitsPerWeek
              ? clientData?.medicalInformation?.alcoholUnitsPerWeek
              : 0,

            current_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
            previous_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
          },
          medicalConditions: [],
          heartFutureTreatment: {},
          heartFutureTreatmentType: 0,
          heartHasArterialSurgery: {},
          heartHasNonArterialSurgery: {},
          strokeDischarged: {},
          respiratoryHighestLungImpairment: 0,
        },
        title: 0,
        forenames: "",
        surname: "",
        middlename: "",
        address: {
          addressID: "",
          line_1: "",
          line_2: "",
          line_3: "",
          line_4: "",
          line_5: "",
          postcode: "",
        },
        maritalStatus: 0,
        sex: 0,
        dob: new Date(),
        occupation: {
          name: "",
          code: "",
        },
        smoker: {},
        medicalHistory: {},
        lifestyleFactors: {},
        relationshipToPartner: 0,
        useAgeNextBirthday: false,
        ccjBankruptcyInd: false,
      },
      client2: {
        impairmentLevel: 0,
        enhancedUnderwriting: {
          activities_of_daily_living: {
            dressing: 0,
            mobility: 0,
            transferring: 0,
            bladder: 0,
            bowels: 0,
            bathing: 0,
            feeding: 0,
            progression: 0,
          },
          lifestyle: {
            height_unit: 0,
            height_value: 0,
            weight_unit: 0,
            weight_value: 0,
            waist_unit: 0,
            waist_value: 0,
            unitsOfAlcoholKnown: {},
            units_of_alcohol: 0,
            current_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
            previous_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
          },
          medicalConditions: [],
          heartFutureTreatment: {},
          heartFutureTreatmentType: 0,
          heartHasArterialSurgery: {},
          heartHasNonArterialSurgery: {},
          strokeDischarged: {},
          respiratoryHighestLungImpairment: 0,
        },
        title: 0,
        forenames: "",
        surname: "",
        middlename: "",
        address: {
          addressID: "",
          line_1: "",
          line_2: "",
          line_3: "",
          line_4: "",
          line_5: "",
          postcode: "",
        },
        maritalStatus: 0,
        sex: 0,
        dob: new Date(),
        occupation: {
          name: "",
          code: "",
        },
        smoker: {},
        medicalHistory: {},
        lifestyleFactors: {},
        relationshipToPartner: 0,
        useAgeNextBirthday: false,
        ccjBankruptcyInd: false,
      },
      productDetails: {
        reversionary: {
          type: 0,
          benefitPcnt: 0,
          start: 0,
          end: 0,
          spouseOption: 0,
          endDateTermYears: 0,
          endDateTermMonths: 0,
          endDateDate: new Date(),
          overlap: {},
        },
        productType: 0,
        fixedTermYears: 0,
        fixedTermMonths: 0,
        requiredOutcome: 0,
        incomelevel: {
          minimumGAD: false,
          maximumGAD: false,
          providerCalculatedRate: false,
        },
        totalFundValue: 0,
      },
    };

    return quoteData;
  }

  return (
    <PortalContext.Provider
      value={{
        insertClientData,
        returnClientData,
        setClientData,
        clientData,
        getNewQuotes,
        getNewQuickQuotes,
        quoteData,
        setQuoteData,
        clearClientContext,
        apiUpdateClientData,
        firstLogin,
        retrieveClientData,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
}

export function usePortal() {
  return useContext(PortalContext);
}
