import * as Yup from "yup";
import { groupedOptions as groupedPensionProviders } from "../../../../models/PensionProviders";
import { pensionTypeLookup } from "../../Lookups";

export const pensionSchema = Yup.object().shape({
  pensions: Yup.array()
    .of(
      Yup.object().shape({
        pensionType: Yup.string().label("Pension Type"),
        cedingScheme: Yup.string().label("Ceding Scheme"),
        valuation: Yup.string().label("Valuation"),
        valuationDate: Yup.string().label("Valuation Date"),
      })
    )
    .label("Pensions")
    .meta({
      fieldArrayName: "Pensions",
      fieldArrayDescription:
        "Please add details for any pensions you have below",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "Lookup",
          props: {
            label: "Pension type (if known)",
            description:
              "Different types of pensions have different rules and benefits which can affect your retirement advice.",
            name: "pensionType",
            tooltip: "",
            values: pensionTypeLookup,
          },
        },
        {
          componentType: "LookupWithSearchAndGroups",
          props: {
            name: "cedingScheme",
            label: "Who is the pension provider?",
            required: false,
            description:
              "Search and select a provider. This is the company or organization that manages your pension.",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            values: groupedPensionProviders,
          },
        },
        {
          componentType: "InputBox",
          props: {
            label: "Valuation",
            description: "How much is in the pension.",
            name: "valuation",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="h3">£</div>,
            validationType: "number",
            minValue: 0,
            maxValue: 10000000,
          },
        },
        {
          componentType: "DateDDMMYYYY",
          props: {
            name: "valuationDate",
            label: "Valuation date",
            required: false,
            description: "This is the date when your pension was last valued.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear() - 10,
            endYear: new Date().getFullYear(),
          },
        },
      ],
    }),
});
