import * as Yup from "yup";
import { Notice } from "../../../common/cards/Notice";
import { AnnuityQuotePage1Schema } from "../Shared/AnnuityQuotePage1Schema";

const header = "Your Pension Fund";

const description = "Provide your pension fund details.";
const stepperDescription = "Provide your pension fund details.";

const schema = AnnuityQuotePage1Schema;

export { header, description, schema, stepperDescription };
